import React from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function Product({
  image,
  price,
  isDiscount = false,
  discount,
  title,
}) {
  return (
    <div className='h-full flex flex-col group w-[238px]'>
      <div className='relative h-[253px] w-[238px]'>
        <img className='absolute inset-0 h-[253px] w-[238px]' src={image} alt='product' />
        <div className='hidden absolute inset-0 h-[253px] w-[238px] bg-black z-10 group-hover:flex opacity-60 items-center'/>
        <p className='hidden justify-center items-center z-20 px-10 absolute group-hover:flex text-xl font-medium text-white text-center h-[253px] w-[238px]'>{title}</p>
        <div className='absolute inset-0 z-10 pr-2 pt-1 flex justify-end'>
          <VisibilityOutlinedIcon sx={{color: '#fff'}} />
        </div>
      </div>
      <div className='flex-1 flex justify-center border-t pl-2 bg-white'>
        <div className='flex'>
          <div>
            <p className='font-medium text-xl whitespace-nowrap'>{`${price} $`}</p>
            <p className={`font-medium text-xl whitespace-nowrap text-primary ${isDiscount && 'line-through'}`}>{isDiscount ? `${discount} $` : ''}</p>
          </div>
          <span className='w-1 h-full border-r border-darkGray mx-2' />
        </div>
        <p className='text-xl font-medium'>{title}</p>
      </div>
    </div>
  );
}
