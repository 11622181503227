import React from 'react';
import { Button, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { cartSelector } from '../store/cartSlice';

export default function CartPrice() {
  const cart = useSelector(cartSelector);

  return (
    <div className='flex-1'>
      <div className='p-5 shadow rounded sticky top-0'>
        <p className='font-poppins text-lg pb-5 font-semiBold'>Summary</p>
        <Divider />
        <div className='pb-10 pt-5'>
          <div className='flex justify-between'>
            <p className='font-poppins'>Total products:</p>
            <p className='font-poppins'>{`${cart.reduce((total, item) => total + item.price, 0)} DH`}</p>
          </div>
          <div className='flex justify-between'>
            <p className='font-poppins'>Shipping costs:</p>
            <p className='font-poppins'>Free</p>
          </div>
        </div>
        <Divider />
        <div className='flex justify-between mb-5'>
          <p className='font-poppins font-semiBold'>Total:</p>
          <p className='font-poppins font-semiBold'>{`${cart.reduce((total, item) => total + item.price, 0)} DH`}</p>
        </div>
        <Button
          variant='contained'
          sx={{ backgroundColor: '#C00F0C', width: '100%' }}
        >
          Checkout
        </Button>
      </div>
    </div>
  );
}
