import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: []
  },
  reducers: {
    addToCart: (state, action) => {
      state.cart = [...state.cart, action.payload];
    },
    deleteFromCart: (state, action) => {
      const id = action.payload;
      const index = state.cart.findIndex(item => item.id === id);
      if (index !== -1) {
        const newCart = [...state.cart];
        newCart.splice(index, 1);
        state.cart = newCart;
      }
    }
  }
});

export const {deleteFromCart, addToCart} = cartSlice.actions;

export const cartSelector = state => state.cart.cart;

export default cartSlice.reducer;