import React, { useState } from 'react';
import { Badge, IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import { ReactComponent as CartIcon } from '../assets/icons/cart.svg';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { handleClickPage } from '../function/products';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cartSelector } from '../store/cartSlice';

export default function Header({
  previousUrl,
  nextUrl,
  setProducts,
  handelFilterProduct,
  activeItem,
  setActiveItem
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const cart = useSelector(cartSelector);
  const navigate = useNavigate();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className='justify-between items-center px-20 py-5 bg-white shadow hidden sm:flex'>
        {/* left */}
        <div className='flex items-center'>
          <IconButton onClick={handleClickMenu}>
            <MenuOutlinedIcon sx={{fontSize: '45px', color: '#000'}}/>
          </IconButton>
          <span className='border-r-2 border-darkGray w-1 h-12 mx-2' />
          <IconButton onClick={() => navigate('/cart')}>
            <Badge badgeContent={cart.length} color='error' max={99}>
              <ShoppingCartOutlinedIcon sx={{fontSize: '45px', color: '#000'}}/>
            </Badge>
          </IconButton>
        </div>
        {/* center */}
        <div>
          <span className='font-bold text-3xl text-primary'>M</span>
          <span className='font-bold text-3xl'>y</span>
          <span className='font-bold text-3xl text-primary'>T</span>
          <span className='font-bold text-3xl'>iss</span>
          <span className='font-bold text-3xl text-primary'>B</span>
          <span className='font-bold text-3xl'>rode</span>
        </div>
        {/* right */}
        <div className='flex'>
          <div className='flex justify-center items-center w-12 h-12'>
            <IconButton onClick={() => handleClickPage(setProducts, previousUrl)}>
              <ArrowBackIosRoundedIcon sx={{ fontSize: 40, color: previousUrl === null ? 'gray' : 'black' }} />
            </IconButton>
          </div>
          <div className='flex justify-center items-center w-12 h-12'>
            <IconButton onClick={() => handleClickPage(setProducts, nextUrl)}>
              <ArrowForwardIosRoundedIcon sx={{ fontSize: 40, color: nextUrl === null ? 'gray' : 'black' }} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between items-center px-20 py-5 bg-white shadow sm:hidden'>
        {/* center */}
        <div>
          <span className='font-bold text-3xl text-primary'>M</span>
          <span className='font-bold text-3xl'>y</span>
          <span className='font-bold text-3xl text-primary'>T</span>
          <span className='font-bold text-3xl'>iss</span>
          <span className='font-bold text-3xl text-primary'>B</span>
          <span className='font-bold text-3xl'>rode</span>
        </div>
        <div className='flex justify-between items-center mt-5'>
          {/* left */}
          <div className='flex items-center'>
            <IconButton onClick={handleClickMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              onClick={handleCloseMenu}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    minWidth: '100px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <MenuItem
                sx={{
                  backgroundColor: `${activeItem === 'all products' && '#C00F0C'}`,
                  color: `${activeItem === 'all products' && 'white'}`,
                  '&:hover': {
                    backgroundColor: '#C00F0C',
                    color: 'white',
                  },
                }}
                onClick={() => {
                  setActiveItem('all products');
                  handelFilterProduct('all products');
                  handleCloseMenu();
                }}
              >
                All products
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: `${activeItem === 'nappe' && '#C00F0C'}`,
                  color: `${activeItem === 'nappe' && 'white'}`,
                  '&:hover': {
                    backgroundColor: '#C00F0C',
                    color: 'white',
                  },
                }}
                onClick={() => {
                  setActiveItem('nappe');
                  handelFilterProduct('nappe');
                  handleCloseMenu();
                }}
              >
                Tablecloth
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: `${activeItem === 'tissus' && '#C00F0C'}`,
                  color: `${activeItem === 'tissus' && 'white'}`,
                  '&:hover': {
                    backgroundColor: '#C00F0C',
                    color: 'white',
                  },
                }}
                onClick={() => {
                  setActiveItem('tissus');
                  handelFilterProduct('tissus');
                  handleCloseMenu();
                }}
              >
                Fabrics
              </MenuItem>
            </Menu>
            <span className='border-r-2 border-darkGray w-1 h-12 mx-2' />
            <IconButton onClick={() => navigate('/cart')}>
              <Badge badgeContent={cart.length} color='error' max={99}>
                <CartIcon />
              </Badge>
            </IconButton>
          </div>
          {/* right */}
          <div className='flex'>
            <div className='flex justify-center items-center w-12 h-12'>
              <IconButton onClick={() => handleClickPage(setProducts, previousUrl)}>
                <ArrowBackIosRoundedIcon sx={{ fontSize: 40, color: previousUrl === null ? 'gray' : 'black' }} />
              </IconButton>
            </div>
            <div className='flex justify-center items-center w-12 h-12'>
              <IconButton onClick={() => handleClickPage(setProducts, nextUrl)}>
                <ArrowForwardIosRoundedIcon sx={{ fontSize: 40, color: nextUrl === null ? 'gray' : 'black' }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
