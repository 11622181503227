import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "./home";
import Cart from "./cart";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/cart',
    element: <Cart />
  },
]);
