import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, DialogActions, DialogContent, ThemeProvider } from '@mui/material';

export default function DetailsProductDialog({
  open,
  setOpen,
  product,
  onClickAddCart,
}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>{product?.description}:</DialogTitle>
        <DialogContent>
          <div className='border shadow p-2 mb-5'>
            <p className='mb-2'>Image 1:</p>
            <img src={`${backendUrl}/storage/products/${product?.image1}`} alt='1' />
          </div>
          <div className='border shadow p-2 mb-5'>
            <p className='mb-2'>Image 2:</p>
            <img src={`${backendUrl}/storage/products/${product?.image2}`} alt='2' />
          </div>
          <div className='border shadow p-2 mb-5'>
            <p className='mb-2'>Image 3:</p>
            <img src={`${backendUrl}/storage/products/${product?.image3}`} alt='3' />
          </div>
          <div className='border shadow p-2 mb-5'>
            <p className='mb-2'>Image 4:</p>
            <img src={`${backendUrl}/storage/products/${product?.image4}`} alt='4' />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => onClickAddCart(product)}
          >
            Ajouter au panier
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '500px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          marginLeft: '20px',
          marginRight: '35px',
          paddingTop: '10px',
          paddingBottom: '10px',
          backgroundColor: '#C00F0C',
          width: '100%'
        }
      }
    }
  },
});