import axios from "axios";

async function index(setProducts, setFilterProducts, url) {
  await axios.post(url)
    .then((response) => {
      console.log(response.data);
      setProducts(response.data);
      setFilterProducts(response.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

function handleClickPage(setProducts, url) {
  if (url !== null) {
    index(setProducts, url);
  }
}

function handleClickProduct(product, setSelectedProduct, setOpenProduct) {
  setSelectedProduct(product);
  setOpenProduct(true);
}

export {index, handleClickPage, handleClickProduct};