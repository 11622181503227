import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from './components/header';
import Product from './components/product';
import { handleClickProduct, index } from './function/products';
import DetailsProductDialog from './components/detailsProduct';
import { addToCart } from './store/cartSlice';

export default function Home() {
  const [products, setProducts] = useState([]);
  const [filterProduct, setFilterProducts] = useState(products.data);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openProduct, setOpenProduct] = useState(false);
  const [activeItem, setActiveItem] = useState('all products');

  const dispatch = useDispatch();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handelFilterProduct = (categorie) => {
    if (categorie === 'all products') {
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/products?page=1`;
      index(setProducts, setFilterProducts, url);
    } else {
      setFilterProducts(products.data.filter((product) => product.categorie === categorie));
    }
  }
  
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/products?page=1`;
    index(setProducts, setFilterProducts, url);
  }, []);

  return (
    <div className='min-h-screen flex flex-col'>
      <ToastContainer />
      <Header
        previousUrl={products.prev_page_url}
        nextUrl={products.next_page_url}
        setProducts={setFilterProducts}
        handelFilterProduct={(categorie) => handelFilterProduct(categorie)}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
      {filterProduct && filterProduct.length === 0
        && (
          <>
          <div className='flex-1 m-10 flex justify-center flex-wrap justify-items'>
            {[1,2,3,4, 5, 6, 7, 8].map((item) => {
              return (
                <div key={item} className='shadow rounded border bg-white opacity-50 p-5 m-5 text-center font-poppins lg:h-[253px] w-[60vw] md:w-[238px] flex justify-center items-center'>Aucun produit</div>
              )
            })}
          </div>
          </>
        )
      }
      <div className='w-full flex justify-center'>
        <DetailsProductDialog
          open={openProduct}
          setOpen={setOpenProduct}
          product={selectedProduct}
          onClickAddCart={(product) => {
            dispatch(addToCart(product));
            setOpenProduct(false);
            toast.success('the product has been added to the cart', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce,
            });
          }}
        />
        <div className='flex justify-center gap-12 flex-wrap mx-6 pt-10 px-14 xl:w-[1280px]'>
          {filterProduct && filterProduct.map((product) => {
            return (
              <div
                className='flex items-center justify-items mb-5 cursor-pointer border shadow'
                onClick={() => handleClickProduct(product, setSelectedProduct, setOpenProduct)}
              >
                <Product
                  key={product.id}
                  image={`${backendUrl}/storage/products/${product.image1}`}
                  price={product.price}
                  isDiscount={product.price !== product.discount}
                  discount={product.discount}
                  title={product.description}
                />
              </div>
            );
          })}
          
          
        </div>
      </div>
    </div>
  );
}
