import React from 'react';
import { useDispatch } from 'react-redux';
import { addToCart, deleteFromCart } from '../store/cartSlice';

function CartInputNumber({
  value,
  addToCart,
  deleteFromCart,
}) {
  return (
    <div className='flex justify-center'>
      <div
        className='w-6 h-6 flex justify-center items-center bg-primary text-white hover:opacity-50 cursor-pointer'
        onClick={() => deleteFromCart()}
      >
        -
      </div>
      <div className='border w-6 h-6 flex justify-center items-center'>{value}</div>
      <div
        className='w-6 h-6 flex justify-center items-center bg-primary text-white hover:opacity-50 cursor-pointer'
        onClick={() => addToCart()}
      >
        +
      </div>
    </div>
  );
}

export default function CartTable({
  header,
  data,
}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const dispach = useDispatch();

  return (
    <div className='w-2/3 rounded shadow p-5'>
      <p className='font-poppins text-lg font-semiBold'>Shopping cart items</p>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th 
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                <td>
                  <div className='flex items-center m-2'>
                    <img src={`${backendUrl}/storage/products/${item.image1}`} alt='product' className='w-20'/>
                    <span className='font-poppins font-normal text-sm/[16px] ml-3'>{item.description}</span>
                  </div>
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center m-2'>
                  <CartInputNumber
                    value={item.quantite}
                    addToCart={() => dispach(addToCart(item))}
                    deleteFromCart={() => dispach(deleteFromCart(item.id))}
                  />
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center m-2'>{parseInt(item.price) * parseInt(item.quantite)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px]'>No products</div>
      )}
    </div>
  );
}