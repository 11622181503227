import React from 'react';
import { useNavigate } from 'react-router-dom';
import CartTable from './components/cartTable';
import CartPrice from './components/cartPrice';
import { useSelector } from 'react-redux';
import { cartSelector } from './store/cartSlice';
import { IconButton, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function Cart() {
  const navigate = useNavigate();
  const cart = useSelector(cartSelector);

  const groupByIdWithQuantity = (items) => {
    return items.reduce((acc, item) => {
      const existing = acc.find(prod => prod.id === item.id);
      if (existing) {
        existing.quantite += 1;
      } else {
        acc.push({ ...item, quantite: 1 });
      }
      return acc;
    }, []);
  };

  return (
    <div className='flex flex-col h-screen'>
      <div className='px-10 flex justify-between items-center shadow-sm h-20'>
        <div className='cursor-pointer py-5' onClick={() => navigate('/')}>
          <span className='font-bold text-3xl text-primary'>M</span>
          <span className='font-bold text-3xl'>y</span>
          <span className='font-bold text-3xl text-primary'>T</span>
          <span className='font-bold text-3xl'>iss</span>
          <span className='font-bold text-3xl text-primary'>B</span>
          <span className='font-bold text-3xl'>rode</span>
        </div>
        <div className='flex justify-center items-center'>
          <Tooltip title='Home'>
            <IconButton onClick={() => navigate('/')}>
              <HomeIcon sx={{fontSize: '30px', color: '#000'}}/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className='flex justify-center my-5 gap-5 mx-10 my-5 flex-1'>
        <CartTable
          header={[
            'Product',
            'Quantity',
            'Price'
          ]}
          data={groupByIdWithQuantity(cart)}
        />
        <CartPrice />
      </div>
    </div>
  );
}
